// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-concept-art-tsx": () => import("./../../../src/pages/concept-art.tsx" /* webpackChunkName: "component---src-pages-concept-art-tsx" */),
  "component---src-pages-countdown-tsx": () => import("./../../../src/pages/countdown.tsx" /* webpackChunkName: "component---src-pages-countdown-tsx" */),
  "component---src-pages-figurative-tsx": () => import("./../../../src/pages/figurative.tsx" /* webpackChunkName: "component---src-pages-figurative-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ink-tsx": () => import("./../../../src/pages/ink.tsx" /* webpackChunkName: "component---src-pages-ink-tsx" */),
  "component---src-pages-monkey-spin-tsx": () => import("./../../../src/pages/monkey-spin.tsx" /* webpackChunkName: "component---src-pages-monkey-spin-tsx" */),
  "component---src-pages-pixel-art-tsx": () => import("./../../../src/pages/pixel-art.tsx" /* webpackChunkName: "component---src-pages-pixel-art-tsx" */),
  "component---src-pages-printmaking-catrina-tsx": () => import("./../../../src/pages/printmaking/catrina.tsx" /* webpackChunkName: "component---src-pages-printmaking-catrina-tsx" */),
  "component---src-pages-projects-animal-dugnad-tsx": () => import("./../../../src/pages/projects/animal-dugnad.tsx" /* webpackChunkName: "component---src-pages-projects-animal-dugnad-tsx" */),
  "component---src-pages-projects-triptych-tsx": () => import("./../../../src/pages/projects/triptych.tsx" /* webpackChunkName: "component---src-pages-projects-triptych-tsx" */)
}

